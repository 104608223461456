import React from 'react';

const SubHistoCollectionList = props => {
    let subHistoCollectionData = props.subHistoCollectionList;
    const {action, date} = subHistoCollectionData.subHisto;
    const label = subHistoCollectionData.subHisto.service['label']
    const labels = props.labels;

    return (
        <div className="info__separator">
            {action && <p><span className="info__label">{labels.action}</span> : {action === "1" ? "Subscription" : "Unsubscription"}</p>}
            {date && <p><span className="info__label">{labels.date}</span> : {date}</p>}
            {label && <p><span className="info__label">{labels.label}</span> : {label}</p>}
        </div>
    );
}
 
export default SubHistoCollectionList;