import React from "react";
// import { useHistory } from "react-router-dom";

const EmailSent = (props) => {
  // STORE ONLY THE CONTENT WE NEED
  const {mail_sent} = props.appContent.data.sections.mail_verification;

  return (
    <div className="email_sent_confirmation">
      <p>{mail_sent} {props.userEmail}</p>
    </div>
  );
};

export default EmailSent;
