import qs from "qs";
import React, { useRef, useState } from 'react';
import {useLocation} from "react-router";
import StepTemplate from "../StepTemplate/StepTemplate"
import RequestSuccess from "../RequestSuccess/RequestSuccess"


const CustomerServiceRequest = props => {

    const [requestMessage, setRequestMessage] = useState('');
    const [requestSent, setRequestSent] = useState('');

    const location = useLocation();
    const subjectSelected = location.state.customerRequestSubject;

    const {_stepPhase} = {...props.appContent.data.sections.customer_service_request}
    const {header_text, header_request} = {...props.appContent.data.sections.customer_service_request.header};
    const {submit, global_previous_choice, back_previous_choice, step} = {...props.appContent.data.global};

    const successRequestContent = {...props.appContent.data.sections.request_success, global_previous_choice, back_previous_choice, step};

    const stepData = {
        "stepPhase":_stepPhase,
        header_text,
        header_request,
        global_previous_choice,
        back_previous_choice,
        step
      };

      const textAreaRef = useRef(null);

      let customerRequestText = {...props.appContent.data.sections.customer_service_request}
      customerRequestText = customerRequestText[subjectSelected];

      const handleSubmit = (event) => {
        event.preventDefault();
          if(requestMessage !== '' && requestMessage !== null) {
              const fetchedToken = sessionStorage.getItem('token');
              const request = {
                  subject: subjectSelected,
                  message: requestMessage
                }

              if(requestSent=='LOADING')
              {
                  return true;
              }
              setRequestSent('LOADING');
            fetch(
                props.appContent.acEndpoint+'/gdpremail.json?nocache',
                {
                method:'POST',
                body: qs.stringify({
                    subject: request.subject,
                    message: request.message
                }),
                headers: {
                    'Authorization': `Bearer ${fetchedToken}`,
                    'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
                }
            })
              .then((response) => response.json())
              .then((response) => {
                  setRequestSent(response.success ? 'OK' : 'ERROR');
            })
          }
          else {
            textAreaRef.current.classList.add('empty-textarea')
            setTimeout(() => {
                textAreaRef.current.classList.remove('empty-textarea')
            }, 3500);
          }
      }

      const handleChange = event => {
          setRequestMessage(event.target.value);
      }

    if(requestSent === 'OK') {
        return (
            <RequestSuccess content={successRequestContent} />
          )
    }
    else if(requestSent === 'LOADING') {
        return (
            <div>
                <StepTemplate data={stepData} appContent={props.appContent} />
                <form disabled="disabled" className="customer__service__form default-form" onSubmit={handleSubmit}>
                    <textarea ref={textAreaRef} value={requestMessage} onChange={handleChange}></textarea>
                    <input className="step_validate" type="button" value="..." />
                </form>
            </div>
        );
    }
    else if(requestSent === 'ERROR') {
        return (
            <div>
                <StepTemplate data={stepData} appContent={props.appContent} />
                <form className="customer__service__form default-form" onSubmit={handleSubmit}>
                    <textarea ref={textAreaRef} value={requestMessage} onChange={handleChange}></textarea>
                    ERROR
                    <input className="step_validate" type="submit" value={submit} />
                </form>
            </div>
        );
    }
    else {
        return (
            <div>
                <StepTemplate data={stepData} appContent={props.appContent} />
                <form className="customer__service__form default-form" onSubmit={handleSubmit}>
                    <textarea ref={textAreaRef} value={requestMessage} onChange={handleChange}></textarea>
                    <input className="step_validate" type="submit" value={submit} />
                </form>
            </div>
        );
    }
}
 
export default CustomerServiceRequest;
