import React, { useEffect, useState } from "react";
import Loader from "../../Loader/Loader";

import "./PersonalData.scss"

import StepTemplate from "../../StepTemplate/StepTemplate";
import RecipientCollectionList from "../../PersonalDataAccess/RecipientCollectionList";
import LoyaltyCollectionList from "../../PersonalDataAccess/LoyaltyCollectionList";
import TransacsCollectionList from "../../PersonalDataAccess/TransacsCollectionList";
import SubscriptionsCollectionList from "../../PersonalDataAccess/SubscriptionsCollectionList";
import SubHistoCollectionList from "../../PersonalDataAccess/SubHistoCollectionList";
import HistEventCollectionList from "../../PersonalDataAccess/HistEventCollectionList";
import BroadLogRcpCollectionList from "../../PersonalDataAccess/BroadLogRcpCollectionList";

const PersonalDataAccess = (props) => {
  const [recipientData, setRecipientData] = useState({});

  const {_stepPhase} = {...props.appContent.data.sections.personal_data_access}
  const {header_text, header_request} = {...props.appContent.data.sections.personal_data_access.header};
  const {global_previous_choice, back_previous_choice,step} = {...props.appContent.data.global};
  const {labels} = {...props.appContent.data.sections.personal_data_access};

  const stepData = {
      "stepPhase":_stepPhase,
      header_text,
      header_request,
      global_previous_choice,
      back_previous_choice,
      step
    };

  useEffect(() => {
    let fetchedToken = sessionStorage.getItem("token");
      fetch(props.appContent.acEndpoint+'/getgdprinfo.json?nocache&output=json', {
        headers: {
          Authorization: `Bearer ${fetchedToken}`,
        },
      })
      .then((response) => response.json())
      .then((response) => setRecipientData(response.data));
  }, [setRecipientData]);

  if (recipientData['recipient-collection']) {
    const recipientCollection = recipientData['recipient-collection'];
    const loyaltyCollection = recipientData['loyaltyHeader-collection'];
    const transacsCollection = recipientData['transacHeader-collection'];
    const subscriptionsCollection = recipientData['subscription-collection'];
    const histEventsCollection = recipientData['histEvent-collection'];
    const subHistoCollection = recipientData['subHisto-collection'];
    const broadLogRcpCollection = recipientData['broadLogRcp-collection'];

    return (
      <>
        <StepTemplate data={stepData} appContent={props.appContent} />
      <hr className="default__hr" />
        <div className="recipient__informations">
          <>
            <h3>{labels.recipientCollection.title}</h3>
            {
              recipientCollection && recipientCollection.map((recipientCollectionArray, recipientCollectionArrayIndex) => {
                return (
                  <RecipientCollectionList labels={labels.recipientCollection} recipientCollectionData={recipientCollectionArray} />
                )
              })
            }
          </>
          <>
          <hr className="default__hr" />
          <h3>{labels.loyaltyCollection.title}</h3>
            {
              loyaltyCollection && loyaltyCollection.map((loyaltyCollectionArray, loyaltyCollectionArrayIndex) => {
                return (
                  <LoyaltyCollectionList labels={labels.loyaltyCollection} loyaltyCollectionData={loyaltyCollectionArray} />
                )
              })
            }
          </>
          <>
          <hr className="default__hr" />
          <h3>{labels.transacsCollection.title}</h3>
            {
              transacsCollection && transacsCollection.map((transacsCollectionArray) => {
                return (
                  <TransacsCollectionList labels={labels.transacsCollection} transacsCollectionList={transacsCollectionArray} />
                )
              })
            }
          </>
          <hr className="default__hr" />
          <h3>{labels.subscriptionsCollection.title}</h3>
          <>
            {
              subscriptionsCollection && subscriptionsCollection.map((subscriptionCollectionArray) => {
                return (
                  <SubscriptionsCollectionList labels={labels.subscriptionsCollection} subscriptionsCollectionList={subscriptionCollectionArray} />
                )
              })
            }
          </>
          <hr className="default__hr" />
          <h3>{labels.histEventCollection.title}</h3>
          <>
            {
              histEventsCollection && histEventsCollection.map((histEventCollectionArray) => {
                return (
                  <HistEventCollectionList labels={labels.histEventCollection} histEventCollectionList={histEventCollectionArray} />
                )
              })
            }
          </>
          <hr className="default__hr" />
          <h3>{labels.subHistoCollection.title}</h3>
          <>
            {
              subHistoCollection && subHistoCollection.map((subHistoCollectionArray) => {
                return (
                  <SubHistoCollectionList labels={labels.subHistoCollection} subHistoCollectionList={subHistoCollectionArray} />
                )
              })
            }
          </>
          <hr className="default__hr" />
          <h3>{labels.broadLogRcp.title}</h3>
          <>
            {
              broadLogRcpCollection && broadLogRcpCollection.map((broadLogRcpCollectionArray) => {
                return (
                  <BroadLogRcpCollectionList labels={labels.broadLogRcp} broadLogRcpList={broadLogRcpCollectionArray} />
                )
              })
            }
          </>
        </div>
      </>
    );
  } else {
    return <Loader />;
  }
};

export default PersonalDataAccess;
