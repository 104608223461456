import React, { useState, Fragment, useEffect } from "react";

import { useLocation, useHistory } from "react-router-dom";
import "./StepTemplate.scss";
import StepTracker from "../StepTracker/StepTracker";
import Loader from "../Loader/Loader";

import backIcon from "../../images/icons/back.svg";
import helpIcon from "../../images/icons/helpIcon.svg"

const StepTemplate = (props) => {
  const {
    stepPhase,
    stepName,
    header_text,
    previous_choice,
    header_request,
    choices,
    global_previous_choice,
    back_previous_choice,
    submit,
    step
  } = props.data;

  const [radioChecked, setRadioChecked] = useState(false);
  const [userTokenChecked, setUserTokenChecked] = useState(false);

  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    let fetchedToken = sessionStorage.getItem("token");
    if (fetchedToken !== null && fetchedToken !== "") {
      setUserTokenChecked(true);
    }
    else {
      setUserTokenChecked(false)
    }
  }, [userTokenChecked]);

  // On validation, go to selected step
  const handleValidateStep = () => {
    const firstStepChoice = document.querySelector("input.step_choice:checked")
      .value;
    const brand =
      document
        .querySelector("input.step_choice:checked")
        .getAttribute("brand") || "";
    const customerRequestSubject = document.querySelector("input.step_choice:checked").getAttribute("customerRequestSubject") || "";
    const previousChoice =
      document
        .querySelector("input.step_choice:checked")
        .getAttribute("previousChoice") || "";
    history.push({
      pathname: `/${firstStepChoice}`,
      search: (props.appContent.env ? '?env='+props.appContent.env : ''),
      state: { brand, customerRequestSubject, previousChoice },
    });
  };

  const handleRadioClick = () => {
    setRadioChecked(true);
  };
  
  const goBack = () => {
    history.goBack();
  };

  const handleHelpClick = (event, id) => {
    event.preventDefault();
    // Close all helps
    const allHelps = document.querySelectorAll(`[data-expand]`);
    for(const item of allHelps) {
      item.classList.remove('visible')
    }
    
    // Expand selected help 
    const selectedHelp = document.querySelectorAll(`[data-expand='${id}']`)
    selectedHelp[0].classList.toggle('visible')
  }
  
  if (userTokenChecked) {
    return (
      <div className="step_template">
        <StepTracker stepText={step} stepPhase={stepPhase} />
        {/* If there is a previous choice, show a back link */}
        {stepPhase !== "1" && (
          <div className="step_template--previous_choice">
            <p>{global_previous_choice}</p>
            <p className="previousChoice">
              {location.state.previousChoice || ""}
            </p>
            <button className="step__template--button" onClick={goBack}>
              <span>
                <img className="previous-icon" src={backIcon} alt="Back" />
              </span>
              {back_previous_choice}
            </button>
            <hr />
          </div>
        )}

        <div className="step_template--header_text">
          {header_text && <p>{header_text}</p>}
          {header_request && <h3>{header_request}</h3>}
        </div>

        {choices && (
          <>
            <div className="step--radio">
              <hr />

              {Object.keys(choices).map((item) => (
                <Fragment>
                  <div
                    key={choices[item]._id}
                    className="radio_select"
                    attr-id={choices[item]._subject_id || choices[item]._id}
                  >
                    <div className="choice_item">
                      <input
                        className="radio--squared step_choice"
                        type="radio"
                        onChange={handleRadioClick}
                        name={stepName}
                        value={choices[item]._id}
                        id={choices[item]._brand_opposition_route || choices[item]._subject_id || choices[item]._brand || choices[item]._id}
                        brand={choices[item]._brand || ""}
                        customerRequestSubject={choices[item]._subject_id || ""}
                        previousChoice={choices[item].object}
                      />
                      <label htmlFor={choices[item]._brand_opposition_route || choices[item]._subject_id || choices[item]._brand || choices[item]._id}>
                        {choices[item].object}
                        {choices[item].note && 
                          <span className="choice_note" onClick={(event) => handleHelpClick(event, choices[item]._brand_opposition_route || choices[item]._id)}>
                            <img src={helpIcon} alt="Question Mark" />
                          </span>
                        }
                      </label>
                    </div>
                    <p className="help_note" data-expand={choices[item]._brand_opposition_route || choices[item]._id}>
                      {choices[item].note}
                    </p>
                  </div>
                  <hr />
                </Fragment>
              ))}
            <button
              className={`step_validate ${
                radioChecked === false ? "disabled" : ""
              }`}
              onClick={handleValidateStep}
            >
              {submit}
            </button>
            </div>
          </>
        )}
      </div>
    );
  } else {
    return (
      <Loader />
    )
  }
};

export default StepTemplate;
