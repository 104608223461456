import React from 'react';

const HistEventCollectionList = props => {
    let histEventCollectionData = props.histEventCollectionList.histEvent;
    const labels = props.labels;
    return (
        <div className="info__separator">
        {Object.keys(histEventCollectionData).map((histEventItem) => {
            let histEventLabel = histEventItem.toLowerCase()
            return (
                <p><span className="info__label">{labels[histEventLabel] || histEventItem}</span> : {histEventCollectionData[histEventItem]}</p>
            )
        })}
        </div>
    );
}
 
export default HistEventCollectionList;