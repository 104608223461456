import React from 'react';

import StepTemplate from "../../StepTemplate/StepTemplate";

const OppositionLimitationDataRights = (props) => {
  const {_stepPhase, _stepName} = {...props.appContent.data.sections.object_opposition_limitation_data_rights};
  const {header_text, header_request} = {...props.appContent.data.sections.object_opposition_limitation_data_rights.header};
  const choices = [...props.appContent.data.sections.object_opposition_limitation_data_rights.choices];
  const {submit, global_previous_choice, back_previous_choice,step} = {...props.appContent.data.global};

  const stepData = {
    "stepPhase":_stepPhase,
    "stepName":_stepName,
    header_text,
    header_request,
    choices,
    global_previous_choice,
    back_previous_choice,
    submit,
    step
  };
    return (
        <StepTemplate data={stepData} appContent={props.appContent} />
    );
}
 
export default OppositionLimitationDataRights;
