import React, { useState } from 'react';
import StepTemplate from "../../StepTemplate/StepTemplate";
import RequestSuccess from "../../RequestSuccess/RequestSuccess";

const PersonalDataDeletion = (props) => {

    const [deletionAsked, setDeletionAsked] = useState(false);
    const [errorOnRequest, setErrorOnRequest] = useState(false)

    // Content
    const {_stepPhase} = {...props.appContent.data.sections.personal_data_deletion}
    const {header_text, header_request} = {...props.appContent.data.sections.personal_data_deletion.header};
    const {global_previous_choice, back_previous_choice,step} = {...props.appContent.data.global};
    const {error_occured, text_button} = {...props.appContent.data.sections.personal_data_deletion}
    const successRequestContent = {...props.appContent.data.sections.request_success, global_previous_choice, back_previous_choice, step};

    const stepData = {
        "stepPhase":_stepPhase,
        header_text,
        header_request,
        global_previous_choice,
        back_previous_choice,
        step
      };


      const handleClick = () => {
        let fetchedToken = sessionStorage.getItem('token');
        fetch(props.appContent.acEndpoint+'/createdeleterequest.json?nocache', {
            headers: {
                Authorization: `Bearer ${fetchedToken}`
            }
        })
        .then((response) => response.json())
        .then(response => {
            if(response.data.created == 'true' || response.data.exist == 'true') {
                setDeletionAsked(true);
            }
            else {
                setErrorOnRequest(true)
            }
        })
      }

    if(deletionAsked === true) {
        return (
            <RequestSuccess content={successRequestContent} />
        )
    }

    else if(deletionAsked === false && errorOnRequest === true) {
        return (
            <>
                <StepTemplate data={stepData} appContent={props.appContent} />
                <div className="default__layout">
                    <p>{error_occured}</p>
                </div>
            </>
        );
    }

    else {
        return (
            <>
                <StepTemplate data={stepData} appContent={props.appContent} />
                <div className="default__layout">
                    <button onClick={handleClick} className="step_validate">{text_button}</button>
                </div>
            </>
        )
    }
}
 
export default PersonalDataDeletion;
