import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
// IMPORT COMPONENTS
import TopBanner from "../TopBanner/TopBanner";
import MailChecker from "../MailChecker/MailChecker";
// IMPORT STEP COMPONENTS
import StepOne from "../Steps/StepOne";
import PersonalDataRights from "../Steps/PersonalDataRight";
import OppositionLimitationDataRights from "../Steps/PersonalDataRights/OppositionLimitationDataRights";
import CookiesOpposition from "../Steps/PersonalDataRights/CookiesOpposition";
import BrandsInformationsOpposition from "../Steps/PersonalDataRights/BrandsInformationsOpposition";
import PersonalDataViolation from "../Steps/PersonalDataViolation";
import PersonalDataAccess from "../Steps/PersonalDataRights/PersonalDataAccess";
import BrandCookiesOpposition from "../Actions/BrandCookiesOpposition";
import BrandInformationOpposition from "../Actions/BrandInformationOpposition";
import CustomerServiceRequest from "../Actions/CustomerServiceRequest";

// IMPORT STYLE
import "./App.scss";
import PersonalDataDeletion from "../Steps/PersonalDataRights/PersonalDataDeletion";
import PersonalDataRectification from "../Steps/PersonalDataRights/PersonalDataRectification";
import Loader from "../Loader/Loader";
import PersonalDataRecovery from "../Steps/PersonalDataRights/PersonalDataRecovery";

const App = () => {
  const [userEmail, setUserEmail] = useState("");
  const [authentifiedUserEmail, setAuthentifiedUserEmail] = useState('');
  const [mailSent, setMailSent] = useState(false);
  const [appLanguage, setAppLanguage] = useState("");
  const [fetchedLanguage, setFetchedLanguage] = useState('');
  const [appContent, setAppContent] = useState({}); 
  const [acEndpoint, setAcEndpoint] = useState("https://rest.napali.app/ac"); 
  const [env, setEnv] = useState(""); 
  
  // GET USER LANGUAGE
  useEffect(() => {
    const supportedLanguages = ['fr','en','de','es','it','nl'];
    let clientLocale;

    if(fetchedLanguage !== '') {
      clientLocale = fetchedLanguage;
    } else {
          navigator?.languages !== undefined
            ? (clientLocale = navigator.languages[0])
            : (clientLocale = "en");
    }
      clientLocale = clientLocale.replace("-","_");
    // backup to en 
    if(clientLocale === undefined || clientLocale === null || clientLocale === '' || !supportedLanguages.includes(clientLocale.substring(0,2))) {
      clientLocale = 'en'
    }
    setAppLanguage(clientLocale);
    sessionStorage.setItem('appLanguage', clientLocale)
    fetch(
      `https://cdn.napali.app/static/BI/default/category-assets/experiences/recurring/gdpr_webapp/js/locales/${clientLocale.substring(0,2)}.json`
    )
      .then(function (response) {
        if (response.status !== 200) {
          console.log(
            "Looks like there was a problem. Status Code: " + response.status
          );
          return;
        }

        // Examine the text in the response
        response.json().then(function (data) {
            data.acEndpoint = acEndpoint;
            data.env = env;
          // Set content with the fetched locale
          setAppContent(data);
        });
      })
      .catch(function (err) {
        console.log("Fetch Error :-S", err);
      });
  }, [appLanguage]);

  // GET MAIL FROM URL PARAMETERS
  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const fetchedMail = urlParams.get("userEmail");
    const fetchedLanguage = urlParams.get("language");
    const fetchedEnv = urlParams.get("env");
    if (fetchedMail !== null) {
      setUserEmail(fetchedMail);
    }
    if(fetchedEnv)
    {
        if(fetchedEnv=='stg')
        {
            setAcEndpoint('https://rest.stg.napali.app/ac');
            setEnv('stg');
        }
    }
    if(fetchedLanguage !== null) {
      setFetchedLanguage(fetchedLanguage)
    }
  }, [userEmail]);


  // If content is not loaded, show loading
  if (appContent.data === undefined) {
    return <Loader />;
  }


  if(appContent && appLanguage) {
    return (
    <Router>
      <div className="App">
        <TopBanner userEmail={authentifiedUserEmail} appContent={appContent} />
        <div className="global_container">
          <Switch>
            <Route exact path="/">
              <MailChecker
                mailSent={mailSent}
                setMailSent={setMailSent}
                userEmail={userEmail || ""}
                setUserEmail={setUserEmail}
                appContent={appContent}
                appLanguage={appLanguage}
              />
            </Route>
            <Route path="/step-one">
              <StepOne appContent={appContent} setAuthentifiedUserEmail={setAuthentifiedUserEmail} />
            </Route>
            <Route path="/personal-data-rights">
              <PersonalDataRights appContent={appContent} />
            </Route>
            <Route path="/personal-data-violation">
              <PersonalDataViolation appContent={appContent} />
            </Route>
            <Route path="/personal-data-opposition">
              <OppositionLimitationDataRights appContent={appContent} />
            </Route>
            <Route path="/cookies-opposition">
              <CookiesOpposition appContent={appContent} />
            </Route>
            <Route path="/brands-information-opposition">
              <BrandsInformationsOpposition appContent={appContent} appLanguage={appLanguage || 'en'} userEmail={authentifiedUserEmail} />
            </Route>
            <Route path="/brand-informations-opposition">
              <BrandInformationOpposition appContent={appContent} />
            </Route>
            <Route path="/personal-data-access">
              <PersonalDataAccess appContent={appContent} />
            </Route>
            <Route path="/personal-data-rectification">
              <PersonalDataRectification appContent={appContent} />
            </Route>
            <Route path="/personal-data-deletion">
              <PersonalDataDeletion appContent={appContent} />
            </Route>
            <Route path="/personal-data-recovery">
              <PersonalDataRecovery appContent={appContent} />
            </Route>
            <Route path="/brand-cookies-opposition">
              <BrandCookiesOpposition appContent={appContent} />
            </Route>
            <Route path="/other">
              <CustomerServiceRequest appContent={appContent} />
            </Route>
            <Route path="/customer-service-request">
              <CustomerServiceRequest appContent={appContent} authentifiedUserEmail={authentifiedUserEmail} />
            </Route>
          </Switch>
        </div>
      </div>
    </Router>
  )
} else {
  return <Loader />
}
};

export default App;
