import React, { useEffect } from 'react';
import { useLocation } from 'react-router';
import StepTemplate from '../StepTemplate/StepTemplate';

const BrandInformationOpposition = (props) => {
    const location = useLocation();
    const brandSelected = location.state.brand;
    
    const informationBrandLink = props.appContent.data.sections.brands_informations_opposition_links[brandSelected];
    const informationText = props.appContent.data.sections.brands_informations_opposition_links.text;
    
    const {_stepPhase} = {...props.appContent.data.sections.brands_informations_opposition_links}
    const {header_text, header_request} = {...props.appContent.data.sections.brands_informations_opposition_links.header};
    const {global_previous_choice, back_previous_choice, step} = {...props.appContent.data.global};

    const stepData = {
        "stepPhase":_stepPhase,
        header_text,
        header_request,
        global_previous_choice,
        back_previous_choice,
        step
      };

      useEffect(() => {
      const userEmail = sessionStorage.getItem('userEmail');
      const brand = location.state.brand;
      const appLanguage = sessionStorage.getItem('appLanguage');

        const redirectWithParams = `${informationBrandLink}?email=${encodeURIComponent(userEmail)}&brand=${encodeURIComponent(brand.toUpperCase())}&lang=${encodeURIComponent(appLanguage)}`;
        window.location.href = redirectWithParams
      }, [informationBrandLink, location.state.brand])

    return (
        <>
        <StepTemplate data={stepData} appContent={props.appContent} />
            <a rel="noreferrer" target="_blank" href={informationBrandLink}>{informationText}</a>
        </>
    );
}
 
export default BrandInformationOpposition;
