import React from 'react';

import StepTemplate from "../StepTemplate/StepTemplate";

const PersonalDataViolation = (props) => {
  const {_stepPhase, _stepName} = {...props.appContent.data.sections.object_personal_data_violation};
  const {header_text, header_request} = {...props.appContent.data.sections.object_personal_data_violation.header};
  const choices = [...props.appContent.data.sections.object_personal_data_violation.choices];
  const {submit, global_previous_choice, back_previous_choice,step} = {...props.appContent.data.global};

  const stepData = {
    "stepPhase":_stepPhase,
    "stepName":_stepName,
    header_text,
    header_request,
    choices,
    global_previous_choice,
    back_previous_choice,
    submit,
    step
  };
    return (
        <StepTemplate data={stepData} appContent={props.appContent} />
    );
}
 
export default PersonalDataViolation;
