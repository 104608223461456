import React, { useState, useEffect, useRef } from "react";

import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha
} from 'react-google-recaptcha-v3';

import "./MailChecker.scss";

// Import component
import EmailSent from "../EmailSent/EmailSent";

const MailChecker = (props) => {
  // Props
  const { userEmail, setUserEmail, mailSent, setMailSent, appContent, appLanguage } = props;

  // GET ONLY THE CONTENT WE NEED
  const {
    text1,
    mail_placeholder,
    text2,
    enter_valid_mail,
    recipient_not_found
  } = appContent.data.sections.mail_verification;
  const { submit } = appContent.data.global;
  
  // State
  const [emailInput, setEmailInput] = useState(""); // User email entered
  const [emailError, setEmailError] = useState(false); // Email is not correctly writed
  const [emailNotFound, setEmailNotFound] = useState(false);

  const submitButtonRef = useRef(null);


  // Add prefetched user email to state
  useEffect(() => {
    userEmail !== "" ? setEmailInput(userEmail) : setEmailInput("");
    setEmailError(false)
  }, [userEmail, setEmailInput]);

  // Input handler
  const handleMailChange = (e) => {
    setEmailInput(e.target.value);
  };

  // Check if mail is correct
  const handleMailSubmit = (e) => {
    e.preventDefault();
    var emailRegex = /\S+@\S+\.\S+/;
    if (emailRegex.test(emailInput) === true) {
      setUserEmail(emailInput);
      sendMailTo(emailInput);
    } else {
      setEmailError(true);
    }
    setEmailNotFound(false)
  };

  const handleVerify = () => {
    //submitButtonRef.current.classList.remove('disabled');
  }

  // Send verification mail
  // Change URL here
  const sendMailTo = (emailInput) => {
      fetch(
        props.appContent.acEndpoint+`/sendemailprivacylink.json?email=${emailInput}&nocache&region=Europe&country=${appLanguage.substring(0,2)}&language=${appLanguage}&url=https%3A%2F%2Fprivacy.boardriders.com%2Fstep-one%3Flanguage%3D${appLanguage}`,)
      .then((r) => r.json())
      .then((res) => {
          console.log('result',res);
        if (res.success) {
          setMailSent(true);
        } else {
          setUserEmail('');
          setEmailNotFound(true);
        }
      });
  };

  // WHILE EMAIL IS NOT VALID
  if (mailSent === false) {
    return (
      <div className="mail_checker">
        <p>{text1}</p>
        <form onSubmit={(e) => handleMailSubmit(e)} className="mail_text_container">
          <input
            type="text"
            value={userEmail ? userEmail : emailInput}
            placeholder={mail_placeholder}
            onChange={handleMailChange}
            disabled={userEmail === "" || userEmail == null ? false : true}
            className={`mail_input ${
              emailError === true ? "text_input--error" : ""
            }`}
          />
          <span
            className={`mail_input--error_text ${emailError ? "visible" : ""}`}
          >
            {enter_valid_mail}
          </span>
          <span
            className={`mail_input--error_text ${emailNotFound ? "visible" : ""}`}
          >
            {recipient_not_found}
          </span>
        </form>
        <p>{text2}</p>
        <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA}>
          <GoogleReCaptcha onVerify={handleVerify} />
        </GoogleReCaptchaProvider>
        <button ref={submitButtonRef} onClick={(e) => handleMailSubmit(e)} type='submit' className="mail_submit step_validate" >
          {submit}
        </button>
      </div>
    );
  } else {
    return <EmailSent appContent={appContent} userEmail={emailInput} />;
  }
};

export default MailChecker;
