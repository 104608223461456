import React, { useEffect } from "react";

import "./StepTracker.scss";
import nextIcon from "../../images/icons/next.svg";
const StepTracker = (props) => {
    const { stepPhase, stepText } = props;



    // Add active class on active element fetched by props
    useEffect(() => {
        const activeStep = document.querySelectorAll(`[data-stepphase='${stepPhase}']`);
        activeStep[0].classList.add('active');
    }, [stepPhase]);

  return (
    <div className="step-tracker">
      <div className="step-item" data-stepphase="1"> 
        <p>{stepText} <span className="step-item--number">1</span></p>
        <img className="step-item--next" src={nextIcon} alt=""/>
      </div>
      <div className="step-item" data-stepphase="2">
        <p>{stepText} <span className="step-item--number">2</span></p>
        <img className="step-item--next" src={nextIcon} alt=""/>

      </div>
      <div className="step-item" data-stepphase="3"> 
        <p>{stepText} <span className="step-item--number">3</span></p>
      </div>
    </div>
  );
};

export default StepTracker;
