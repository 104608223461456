import React, { useEffect, useRef, useState } from "react";
import 'react-phone-number-input/style.css';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'

import Loader from "../../Loader/Loader";
import StepTemplate from "../../StepTemplate/StepTemplate";
import RequestSuccess from "../../RequestSuccess/RequestSuccess";

const PersonalDataRectification = (props) => {
  const [recipientInformations, setRecipientInformations] = useState({});
  const [locationInformations, setLocationsInformations] = useState({});
  const [requestSucceed, setRequestSucceed] = useState(false);
  const [phoneValue, setPhoneValue] = useState('')
  const [mobilePhoneValue, setMobilePhoneValue] = useState('')

  const {_stepPhase} = {...props.appContent.data.sections.personal_data_rectification}
  const {header_text, header_request} = {...props.appContent.data.sections.personal_data_rectification.header};
  const {labels} = {...props.appContent.data.sections.personal_data_rectification};
  const {global_previous_choice, back_previous_choice, submit, step} = {...props.appContent.data.global};
  const successRequestContent = {...props.appContent.data.sections.request_success, global_previous_choice, back_previous_choice, step};

  const stepData = {
      "stepPhase":_stepPhase,
      header_text,
      header_request,
      global_previous_choice,
      back_previous_choice,
      step
    };

    const phoneInputRef = useRef(null)
    const mobilePhoneInputRef = useRef(null)
    


  useEffect(() => {
    let fetchedToken = sessionStorage.getItem("token");
      fetch(props.appContent.acEndpoint+'/getgdprinfo.json?nocache&output=json', {
        headers: {
          Authorization: `Bearer ${fetchedToken}`,
        },
      })
      .then((response) => response.json())
      .then((response) =>
        setRecipientInformations(
          {...response.data["recipient-collection"][0].recipient}
        )
      );
  }, []);

  useEffect(() => {
    setLocationsInformations(recipientInformations.location)
  }, [setLocationsInformations, recipientInformations])

  useEffect(() => {
    if(recipientInformations['phone'] && recipientInformations['phone'] !== '') {
      if(!recipientInformations['phone'].startsWith('+')) {
        setPhoneValue('+' + recipientInformations['phone'])
      }
      else {
        setPhoneValue(recipientInformations['phone'] || '')
      }
    }
    if(recipientInformations['mobilePhone'] && recipientInformations.mobilePhone !== '') {
      if(!recipientInformations.mobilePhone.startsWith('+')) {
        setMobilePhoneValue('+' + recipientInformations.mobilePhone)
      }
      else {
        setMobilePhoneValue(recipientInformations.mobilePhone || '')
      }
    }
  }, [recipientInformations.mobilePhone, recipientInformations.phone])

  const handleChange = (e, field) => {
    setRecipientInformations({
      ...recipientInformations,
      [field] : e.target.value
    })
  }

  const handleLocationChange = (e, field) => {
    setLocationsInformations({
      ...locationInformations,
      [field] : e.target.value
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setRecipientInformations({
      ...recipientInformations,
      location: locationInformations
    });

    const scrollToPosition = element => {
      element.scrollIntoView()
    }

    let modifiedObjectUrl = ""

    for(let field in recipientInformations) {
      if(field !== 'location' && field !=='additionalRcp' && field !== 'additionalGeo' && field !=='mobilePhone' && field !== 'phone') {
        modifiedObjectUrl += `${field}=${encodeURIComponent(recipientInformations[field])}&`;
      }
      else {
        for(let itemLocation in locationInformations) {
          if(itemLocation === 'additionalRcp') {
            modifiedObjectUrl += `addrRcp=${encodeURIComponent(locationInformations[itemLocation])}&`;
          }
          else if(itemLocation === 'additionalGeo') {
            modifiedObjectUrl += `addrGeo=${encodeURIComponent(locationInformations[itemLocation])}&`;
          }
          else if(itemLocation === 'street' || itemLocation === 'special') {
            modifiedObjectUrl += `addr${itemLocation}=${encodeURIComponent(locationInformations[itemLocation])}&`;
          }
          else {
            modifiedObjectUrl += `${itemLocation}=${encodeURIComponent(locationInformations[itemLocation])}&`;
          }
        }
      }
    }
    
    let phoneError = false;
    if(mobilePhoneInputRef.current !== null) {
      if(mobilePhoneValue && mobilePhoneValue !== '' && isValidPhoneNumber(mobilePhoneValue)) {
          modifiedObjectUrl += `mobile=${encodeURIComponent(mobilePhoneValue)}&`
      }
      else if(mobilePhoneValue === '' || !mobilePhoneValue) {
        phoneError = true
        mobilePhoneInputRef.current.classList.add('input_error');
        scrollToPosition(mobilePhoneInputRef.current)
      }
      else {
        phoneError = true
        mobilePhoneInputRef.current.classList.add('input_error');
        scrollToPosition(mobilePhoneInputRef.current)
      }
    }


    if(phoneInputRef.current !== null) {
      if(phoneValue && phoneValue !== '' && isValidPhoneNumber(phoneValue)) {
          modifiedObjectUrl += `phone=${encodeURIComponent(phoneValue)}&`
      }
      else if(phoneValue === '' || !mobilePhoneValue) {
        phoneError = true;
        phoneInputRef.current.classList.add('input_error');
        scrollToPosition(mobilePhoneInputRef.current)
      }
      else {
        phoneError = true;
        phoneInputRef.current.classList.add('input_error');
        scrollToPosition(mobilePhoneInputRef.current)
      }
    }

    if(phoneError === false) {
      fetch(props.appContent.acEndpoint+`/createrecipient.json?${modifiedObjectUrl}&version=2`)
        .then((response) => response.json())
        .then((response) => {
            setRequestSucceed(true);
        })
    }

  }

  const displayKeys = [
    "firstName",
    "lastName",
    "birthDate",
    "zipCode",
    "location"
  ];

  if (recipientInformations && requestSucceed === false) {
    return (
      <>
        <StepTemplate data={stepData} appContent={props.appContent} />
        <form className="default-form rectification__form" onSubmit={handleSubmit}>
          {Object.keys(recipientInformations).map((keyName, i) => {
            if (displayKeys.includes(keyName)) {
              let inputType = "text";
              switch(keyName) {
                case 'firstName':
                  inputType='text'
                  break;
                case 'lastName':
                  inputType='text'
                  break;
                case 'birthDate':
                  inputType='date'
                  break;
                case 'zipCode':
                  inputType='number'
                  break;
                default:
                  inputType='text';
                  break;
              }
              if(keyName !== 'location') {
                let keyLabel = keyName.toLowerCase();
                  return (
                    <>
                      <label>
                        {labels[keyLabel] || keyName}
                        <input
                          type={inputType}
                          id={keyName}
                          value={recipientInformations[keyName]}
                          onChange={e =>handleChange(e, keyName)}
                        ></input>
                      </label>
                    </>
                  );
              } else {
                return (
                  locationInformations && Object.keys(recipientInformations.location).map(
                    (locationName) => {
                      let locationLabel = locationName.toLowerCase();
                      return (
                        <label>
                          {labels[locationLabel] || locationName}
                          <input
                            type="text"
                            onChange={e =>handleLocationChange(e, locationName)}
                            id={keyName[locationName]}
                            value={locationInformations[locationName]}
                          ></input>
                        </label>
                      );
                    }
                  )
                )
              }
            }
            else {
              if(keyName === 'phone' && recipientInformations['phone'] !== '' && recipientInformations['phone']) {
                let keyLabel = keyName.toLowerCase();
                return (
                  <>
                  <label>{labels[keyLabel] || keyName}</label>
                  <PhoneInput ref={phoneInputRef} value={phoneValue} onChange={setPhoneValue} placeholder={keyName} />
                  </>
                )
              }
              else if(keyName === 'mobilePhone' && recipientInformations['mobilePhone'] !== '' && recipientInformations['mobilePhone']) {
                let keyLabel = keyName.toLowerCase();

                return (
                  <>
                  <label>{labels[keyLabel] || keyName}</label>
                  <PhoneInput ref={mobilePhoneInputRef} value={mobilePhoneValue} onChange={setMobilePhoneValue} placeholder={keyName} />
                  </>
                ) 
              }
            }
          })}
          <input className="step_validate" type="submit" onSubmit={e => handleSubmit(e)} value={submit} />
        </form>
      </>
    );
  } 
  else if(requestSucceed === true) {
    return (
      <RequestSuccess content={successRequestContent} />
    )
    
  }
  else {
    return <Loader />;
  }
};

export default PersonalDataRectification;
