import React, { useEffect } from 'react';

const BroadLogRcpCollectionList = (props) => {
    let broadLogRcpData = props.broadLogRcpList.broadLogRcp;

    let lastModified = broadLogRcpData.lastModified || null;
    let status = broadLogRcpData.status || null;
    let deliveryLabel = broadLogRcpData.delivery.label || null;

    const labels = props.labels;

    switch (status) {
        case '0':
            status = "Ignored"
            break;
        case '1':
            status = "Sent"
            break;
        case '2':
            status = "Failed"
            break;
        case '3':
            status = "Send Pending"
            break;
        case '4':
            status = "Received"
            break;
        case '5':
            status = "To Send"
            break;
        case '6':
            status = "Not Sent"
            break;
        case '7':
            status = "Prepared"
            break;
        default:
            status=status
            break
    }


    return (
        <div className="info__separator">
            {deliveryLabel && <p><span className="info__label">{labels.deliveryLabel}</span> : {deliveryLabel} </p>}
            {lastModified && <p><span className="info__label">{labels.lastModified}</span> : {lastModified}</p>}
            {status && <p><span className="info__label">{labels.status}</span> : {status}</p>}
        </div>

    );
}
 
export default BroadLogRcpCollectionList;