import React from "react";
import "./TopBanner.scss";

import boardridersLogo from "../../images/boardriders.png"

const TopBanner = (props) => {

  const {your_email} = props.appContent.data.global;
  const {userEmail} = {...props};

    return (
      <div className="topBanner-container">
        <div className="topBanner">
          <img className="topBanner__logo" src={boardridersLogo} alt="Boardriders Logo" />
        </div>
        {userEmail !== '' && 
        <div className="topBanner--mail_reminder">
          <p>
            {`${your_email}`}
            <span className="mail_reminder--mail">{userEmail}</span>{" "}
          </p>
        </div>
        }
      </div>
    );
};

export default TopBanner;
