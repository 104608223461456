import React from 'react';

const SubscriptionsCollectionList = props => {
    let subscriptionsCollectionData = props.subscriptionsCollectionList;

    const {created, label} = subscriptionsCollectionData.subscription.service

    const labels = props.labels;

    return (
        <div className="info__separator">
            <p><span className="info__label">{labels.label}</span> : {label}</p>
            <p> <span className="info__label">{labels.created}</span> : {created} </p>
        </div>
    )
}
 
export default SubscriptionsCollectionList;