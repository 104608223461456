import React from 'react';

const RecipientCollectionList = (props) => {

    const labels = props.labels;

    let recipientCollectionData = props.recipientCollectionData;
    
    if(!Array.isArray(recipientCollectionData)) {
        recipientCollectionData = [];
        recipientCollectionData.push(props.recipientCollectionData)
    }
    
    return (
        <div className="">
            {
                recipientCollectionData.map((recipientCollectionItem) => {
                    if(recipientCollectionItem.recipient) {
                        recipientCollectionItem = recipientCollectionItem.recipient
                    }
                    return (
                        <div className="info__separator">
                            {
                                Object.keys(recipientCollectionItem).map((recipientCollectionDetail) => {
                                    let recipientCollectionLabel = recipientCollectionDetail.toLowerCase();
                                    if(recipientCollectionDetail !== 'location') {
                                        return(
                                            <p><span className="info__label">{labels[recipientCollectionLabel] || recipientCollectionDetail}</span> : {recipientCollectionItem[recipientCollectionDetail]} </p>
                                        )
                                    }
                                    else {
                                        return (
                                            <div className="">
                                            {/* <h3 style={{marginBottom: '20px'}}>Location</h3> */}
                                                {Object.keys(recipientCollectionItem[recipientCollectionDetail]).map((locationDetails) => {
                                                    let locationLabel = locationDetails.toLowerCase()
                                                    return (
                                                        <p><span className="info__label">{labels[locationLabel] || locationDetails}</span> : {recipientCollectionItem[recipientCollectionDetail][locationDetails]}</p>
                                                    )
                                                })}
                                            </div>
                                        )
                                    }
                                })
                            }
                     </div>
                    )
                })
            }
        </div>
    );
}
 
export default RecipientCollectionList;