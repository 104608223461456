import React, { useEffect } from 'react';
import { useHistory } from 'react-router';

const TokenError = (props) => {
    const location = useHistory();

    useEffect(() => {
        if(location.location.pathname !== '/') {
            setTimeout(() => {
                window.location.href = '/'
            }, 3000);

        }
    }, [location.location.pathname])

    if(location.location.pathname !== '/') {
        return (
            <div className="token__error">
                <p style={{textAlign: 'center', padding: '30px'}}>An error occured with the authentification, you will be redirected to the homepage in 3 seconds</p>
            </div>
        );
    } 
    else {
        return (
            <>
            </>
        )
    }
}
 
export default TokenError;