import React, {useEffect, useState} from 'react';

import StepTemplate from "../../StepTemplate/StepTemplate";

const PersonalDataRecovery = (props) => {

    const [recipientData, setRecipientData] = useState({})

    const {_stepPhase} = {...props.appContent.data.sections.personal_data_recovery}
    const {header_text, header_request} = {...props.appContent.data.sections.personal_data_recovery.header};
    const {global_previous_choice, back_previous_choice,step} = {...props.appContent.data.global};

    const download_xml = {...props.appContent.data.sections.personal_data_recovery}
  
    const stepData = {
        "stepPhase":_stepPhase,
        header_text,
        header_request,
        global_previous_choice,
        back_previous_choice,
        step
      };


    // Refs are reference to dom element 
    const downloadButtonRef = React.createRef();

    useEffect(() => {
        let fetchedToken = sessionStorage.getItem("token");
          fetch(props.appContent.acEndpoint+'/getgdprinfo.json?nocache&output=xml', {
            headers: {
              Authorization: `Bearer ${fetchedToken}`,
            },
          })
          .then((response) => response.json())
          .then((response) => setRecipientData(response.data))
      }, [setRecipientData]);

      useEffect(() => {
        const data = "data:text/plain;charset=utf-8," + encodeURIComponent((recipientData));
        downloadButtonRef.current.setAttribute("href",data);
        downloadButtonRef.current.setAttribute("download", "data.xml");
      }, [recipientData, downloadButtonRef])

    return (
      <>
        <StepTemplate data={stepData} appContent={props.appContent} />
        <div className="personal_data_recovery">
          <a ref={downloadButtonRef} id="downloadJSON">{download_xml.download_xml}</a>
        </div>
        </>
    );
}
 
export default PersonalDataRecovery;
