import React from 'react';
import { useLocation } from 'react-router';
import StepTemplate from '../StepTemplate/StepTemplate';

const BrandCookiesOpposition = (props) => {
    const location = useLocation();
    const brandSelected = location.state.brand;
    const cookieBrandLink = props.appContent.data.sections.brands_cookies_opposition_links[brandSelected];
    const cookiePolicyText = props.appContent.data.sections.brands_cookies_opposition_links.text;

    const {_stepPhase} = {...props.appContent.data.sections.brands_cookies_opposition_links}
    const {header_text, header_request} = {...props.appContent.data.sections.brands_cookies_opposition_links.header};
    const {global_previous_choice, back_previous_choice, step} = {...props.appContent.data.global};

    const stepData = {
        "stepPhase":_stepPhase,
        header_text,
        header_request,
        global_previous_choice,
        back_previous_choice,
        step
      };

    return (
        <div>
            <StepTemplate data={stepData} appContent={props.appContent} />
            <a rel="noreferrer" target="_blank" href={cookieBrandLink}>{cookiePolicyText}</a>
        </div>
    );
}
 
export default BrandCookiesOpposition;
