import React from 'react';

import "./RequestSuccess.scss";

import StepTemplate from "../StepTemplate/StepTemplate"

const RequestSuccess = (props) => {
    
    const requestSuccessContent = {...props.content};
    const _stepPhase = requestSuccessContent._stepPhase;
    const {header_text, header_request} = {...requestSuccessContent.header}
    const {back_previous_choice, global_previous_choice, step} = {...requestSuccessContent}

    const stepData = {
        "stepPhase":_stepPhase,
        header_text,
        header_request,
        back_previous_choice,
        global_previous_choice,
        _stepPhase,
        step
      };

    return (
        <>
        <StepTemplate data={stepData} appContent={props.appContent} />
        <div className="request__success">
            <div className="request__success--title">{requestSuccessContent.success_title}</div>
            <p className="request__success--text">{requestSuccessContent.success_text}</p>
        </div>
        </>
    );
}
 
export default RequestSuccess;
