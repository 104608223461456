import React, { useEffect, useState } from "react";
import StepTemplate from "../StepTemplate/StepTemplate";
import jwt_decode from "jwt-decode";
import Loader from "../Loader/Loader";
import TokenError from "../TokenError/TokenError";

const StepOne = (props) => {
  const {_stepPhase, _stepName} = {...props.appContent.data.sections.step1};
  const {header_text, header_request} = {...props.appContent.data.sections.step1.header};
  const choices = [...props.appContent.data.sections.step1.choices];
  const {submit, global_previous_choice, back_previous_choice, step} = {...props.appContent.data.global};

  const [tokenError, setTokenError] = useState();

  const stepData = {
    "stepPhase":_stepPhase,
    "stepName":_stepName,
    header_text,
    header_request,
    choices,
    global_previous_choice,
    back_previous_choice,
    submit,
    step
  }

  useEffect(() => {
    const currentUrl = window.location.search;
    const urlParams = new URLSearchParams(currentUrl);
    const userToken = urlParams.get('token');
    sessionStorage.setItem('token', userToken);
    handleSetUserEmail(userToken);
  });

  const handleSetUserEmail = (token) => {
    if(token) {
      try {
        const decodedToken = jwt_decode(token);
        const expDate = decodedToken.exp;
        if(Date.now() >= expDate * 1000) {
          setTokenError(true)
        }
        else {
          const userEmail = decodedToken.email;
          props.setAuthentifiedUserEmail(userEmail);
          setTokenError(false)
          sessionStorage.setItem('userEmail', userEmail)
        }
      } catch {
        setTokenError(true);
      }
    }
  }

  if(tokenError === true) {
    return (
      <TokenError />
    )
  }

  else if(tokenError === false) {
    return (
      <div className="step_one">
        <StepTemplate data={stepData} appContent={props.appContent} />
      </div>
    );
  }
  else {
    return <Loader />
  }
};

export default StepOne;
