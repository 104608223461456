import React from 'react';

const TransacsCollectionList = props => {

    const labels = props.labels;

    let transacsCollectionData = props.transacsCollectionList;
    if(!Array.isArray(transacsCollectionData)) {
        transacsCollectionData = [];
        transacsCollectionData.push(props.transacsCollectionList)
    }
    return (
        <div className="info__separator">
            {
                transacsCollectionData.map((transacArray) => {
                    if(transacArray.transacHeader) {
                        transacArray = transacArray.transacHeader
                    }
                    return (
                        Object.keys(transacArray).map((transacItem) => {
                            let transacLabel = transacItem.toLowerCase();
                            if(typeof transacArray[transacLabel] === 'object' && transacArray[transacItem] !== null) {
                               return (
                                    Object.keys(transacArray[transacItem]).map((shopItem) => {
                                        let shopLabel = shopItem.toLowerCase();
                                        return (
                                            <p><span className="info__label">{labels[shopLabel] || shopItem}</span> : {transacArray[transacItem][shopItem]}</p>
                                        )
                                    })
                                ) 
                            }
                            return <p><span className="info__label">{labels[transacLabel] || transacItem}</span> : {transacArray[transacItem]}</p>
                        })
                    )
                })
            }
        </div>
    );
}
 
export default TransacsCollectionList;