import React from 'react';

const LoyaltyCollectionList = (props) => {
    const labels = props.labels;
    let loyaltyCollectionData = props.loyaltyCollectionData;
    if(!Array.isArray(loyaltyCollectionData)) {
        loyaltyCollectionData = [];
        loyaltyCollectionData.push(props.loyaltyCollectionData)
    }
    return (
        <div className="info__separator">
            {
                loyaltyCollectionData.map((loyaltyCollectionItem) => {
                    if(loyaltyCollectionItem.loyaltyHeader) {
                        loyaltyCollectionItem = loyaltyCollectionItem.loyaltyHeader
                    }

                    return (
                        Object.keys(loyaltyCollectionItem).map((loyaltyCollectionDetails) => {
                            let loyaltyCollectionLabel = loyaltyCollectionDetails.toLowerCase()
                            if(loyaltyCollectionDetails !== 'loyaltyProgram') {
                                return (
                                    <p><span className="info__label">{labels[loyaltyCollectionLabel] || loyaltyCollectionDetails}</span> : {loyaltyCollectionItem[loyaltyCollectionDetails]}</p>
                                )
                            }
                            else {
                                return (
                                    loyaltyCollectionItem[loyaltyCollectionDetails].map((loyaltyProgramItem) => {
                                        return (
                                            <div className="space_top">
                                                {
                                                Object.keys(loyaltyProgramItem).map((loyaltyProgramItemDetail) => {
                                                    let loyaltyProgramItemLabel = loyaltyProgramItemDetail.toLowerCase()
                                                    return (
                                                            <p><span className="info__label">{labels[loyaltyProgramItemLabel] || loyaltyProgramItemDetail}</span> : {loyaltyProgramItem[loyaltyProgramItemDetail]}</p>
                                                            )
                                                        })
                                                    }
                                            </div>
                                        )
                                    })
                                )
                            }
                        })
                    )
                })
            }
        </div>
    );
}
 
export default LoyaltyCollectionList;